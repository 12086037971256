import React, { Component } from 'react';
import t from '../locales';

class Knowledge extends Component {
  render() {
    return (
      <>
        <h1 className="mb-4">{t('title.knowledge')}</h1>
      </>
    );
  }
}

export default Knowledge;
